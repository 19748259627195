/**************** NEw DASHBOARD START **************/

.db_item {
  border-radius: $b_radius;
  width: 100%;
  text-align: left;
  margin: 15px 0;
  padding: 10px;
  border: 1px solid #eee;
}

.db_item h3 {
  text-align: left;
  // background: $box_background;
  padding: 5px;
  display: block;
  cursor: pointer;
}

.db_item .arrow_closed,
.db_item .arrow_open {
  width: 20px;
  display: inline-block;
}

.db_item a {
  text-decoration: none;
}

.db_item .db_details {
  display: none;
  margin: 10px 0;
}

.db_section {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.db_full {
  @extend .content_section;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.db_big_item_common {
  font: 48px/48px $font-family;
  font-weight: bold;
  text-align: center;
}

.db_big_item_bad {
  @extend .db_big_item_common;
  color: #c01a37;
}

.db_big_item_ok {
  @extend .db_big_item_common;
  color: #269347;
}

.db_big_item_ok > a {
  color: #269347;
  text-decoration: underline;
}

div.db_big_item {
  font: 150%/1.5 $font-family;
  font-weight: bold;
}

div.db_big_item > h2 {
  text-align: center;
}

/**************** NEW DASHBOARD END **************/