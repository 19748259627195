@mixin box_common {
  -webkit-border-radius: $b_radius;
  -moz-border-radius: $b_radius;
  border-radius: $b_radius;
  border: 1px solid;
  padding: 5px 5px 5px 25px;
  width: 50%;
  margin: 0 auto !important;
}

@mixin dark_border {
  border-radius: $b_radius;
  border: 1px solid $dark_border;
}

@mixin stripe_theme( $theme ) {

  @if $theme == "blue" {
    // background-color: #0d1f6f;
    /*
    @include gradient(linear,top,rgb(13, 31, 111),rgb(18, 45, 153),'left top, left bottom');
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#0d1f6f', EndColorStr='#122d99');
    */
    background: url(/img/top_stripe_bg_blue.png) no-repeat 0 0 #0d1f6f;
  }
  @if $theme == "gray" {
    // background-color: #222;
    background: url(/img/top_stripe_bg_gray.png) no-repeat 0 0 #222;
  }
  @if $theme = "slate" {
    background: #626D74;
  }

}

@mixin nav_theme( $theme ) {

  @if $theme == "blue" {
    background: rgb(18, 45, 153);
  }
  @if $theme == "gray" {
    background: #050505;
  }
  @if $theme == "slate" {
    background: #626D74;
  }

}

@mixin footer_theme( $theme ) {

  background-position: bottom right 3px;
  background-repeat: no-repeat;

  @if $theme == "blue" {
    // background-color: #122d99;
    background: url(/img/top_stripe_bg_blue.png) no-repeat 0 0 #0d1f6f;
  }
  @if $theme == "gray" {
    background-color: #222;
  }

}

@mixin form_theme( $theme ) {

  @if $theme == "blue" {
    background-color: #0d1f6f;
    @include gradient(linear,top,rgb(13, 31, 111),rgb(18, 45, 153),'left top, left bottom');
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#0d1f6f', EndColorStr='#122d99');
  }
  @if $theme == "gray" {
    background: #050505;
  }

}