@import "partials/base";

/* Overlay style */
.overlay-delete-account,
.overlay-delete-asset,
.overlay,
.overlay-asset-filter {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $overlay_bg;
    z-index: 100;
}

.overlay-delete-account nav,
.overlay-delete-asset nav,
.overlay nav,
.overlay-asset-filter nav {
    top: 30%;
    position: relative;
    float: none;
    background: transparent;
    color: #fff;
    width: 35%;
    /* height: 30%; */
    margin: 0 auto;
    font-size: 150%;
    text-align: center;
}

.overlay, .overlay-asset-filter {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(51, 148, 204, 0.9);
    z-index: 100
}

.overlay nav, .overlay-asset-filter nav {
    float: none;
    background: transparent
}

.overlay .overlay-close, .overlay-asset-filter .overlay-close-asset-filter {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 20px;
    top: 20px;
    overflow: hidden;
    border: none;
    background: url(/img/cross.png) no-repeat center center;
    text-indent: 200%;
    color: transparent;
    outline: none;
    z-index: 100
}

.overlay nav, .overlay-asset-filter nav {
    text-align: center;
    position: relative;
    top: 50%;
    height: 60%;
    font-size: 54px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.overlay ul, .overlay-asset-filter ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    height: 100%;
    position: relative
}

.overlay ul li, .overlay-asset-filter ul li {
    display: block;
    height: 20%;
    height: calc(100% / 5);
    min-height: 54px
}

.overlay ul li a, .overlay-asset-filter ul li a {
    font-weight: 300;
    display: block;
    color: #fff;
    -webkit-transition: color 0.2s;
    transition: color 0.2s
}

.overlay ul li a:hover, .overlay ul li a:focus, .overlay-asset-filter ul li a:hover, .overlay-asset-filter ul li a:focus, .overlay-asset-filter ul li {
    color: $overlay_hover;
}

.overlay-hugeinc {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
    transition: opacity 0.5s, visibility 0s 0.5s
}

.overlay-hugeinc.open {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s
}

.overlay-hugeinc nav {
    -webkit-perspective: 1200px;
    perspective: 1200px
}

.overlay-hugeinc nav ul {
    opacity: 0.4;
    -webkit-transform: translateY(-25%) rotateX(35deg);
    transform: translateY(-25%) rotateX(35deg);
    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s
}

.overlay-hugeinc.open nav ul {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg)
}

.overlay-hugeinc.close nav ul {
    -webkit-transform: translateY(25%) rotateX(-35deg);
    transform: translateY(25%) rotateX(-35deg)
}

@media screen and (max-height: 30.5em) {
    .overlay nav {
        height: 70%;
        font-size: 34px
    }

    .overlay ul li {
        min-height: 34px
    }
}
