$b_radius: 3px;
$big_radius: 10px;
$box_bg_left: 5px;
$box_bg_top: 5px;
$form_spacer: 165px;

// remember to change bootstrap.css override file
$font-family: 'proxima-nova-soft', sans-serif;

$dark_border: #0d1f6f;
$box_background: #f3f3f3;

$overlay_bg: rgba( 51, 148, 204, 0.9 );
$overlay_hover: #b1e1fc;

$main_theme: "slate";

$bootstrap-icon-path: "/bootstrap-fonts/";