
@import "partials/base";
@import "partials/gradient";
@import "partials/dashboard";
@import "partials/mixins";

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
rp,
rt,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video,
xmp {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

html,
body {
  height: 100%; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block; }

b,
strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

ul,
ol {
  list-style: none; }

li {
  display: list-item; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th,
td,
caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before,
q:after {
  content: ""; }

sub,
sup,
small {
  font-size: 75%; }

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

h1 {
  font-size: 155%; }

h2 {
  font-size: 125%; }

a {
  color: #000; }

footer {
  height: 100px;
  padding: 10px 10px 10px 450px;
  color: #fff;
  @include footer_theme($main_theme);
  text-transform: uppercase;
  position: relative;
}

footer #footer_content {
  position: absolute;
  bottom: 0; }

.footer_item {
  display: inline-block;
  margin: 0 10px 3px 10px; }

figure figcaption {
  width: 300px;
  margin: 10px auto; }

figure img {
  border-radius: $b_radius;
  border: 1px solid #c4c4c4;
}

i.fa {
  margin-right: 10px; }

div.confirmation_buttons {
  margin: 15px 0 0 0;
}

div.home_block {
  width: 100%;
  text-align: center;
  padding: 0 0 10px 0;
  font-family: $font-family; }

div.home_block_no_border {
  border: none !important;
  font-family: $font-family; }

div.home_block > header {
  font-size: 250%;
  margin: 0 0 10px 0; }

.home_block_center {
  text-align: center !important; }

.home_block_sign_up {
  padding: 10px 0 10px 0 !important;
  background: #4b5459;
  color: #d9ebf9; }

.home_block_sign_up a {
  color: #d9ebf9; }

div.home_block_create {
  background: rgb(217,235,249);
  background: -moz-linear-gradient(180deg, rgba(217,235,249,1) 35%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(217,235,249,1) 35%, rgba(255,255,255,1) 100%);
  background: linear-gradient(180deg, rgba(217,235,249,1) 35%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9ebf9",endColorstr="#ffffff",GradientType=1);
}

div.home_block_view {
  background: #626d74;
  color: #fff; }

div.home_block_report {
  background: rgb(217,235,249);
  background: -moz-linear-gradient(180deg, rgba(217,235,249,1) 35%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(217,235,249,1) 35%, rgba(255,255,255,1) 100%);
  background: linear-gradient(180deg, rgba(217,235,249,1) 35%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9ebf9",endColorstr="#ffffff",GradientType=1);
}

div.home_block > p {
  margin: 0 auto;
  padding: 10px 0 10px 0;
  width: 600px;
  text-align: left;
  font-size: 125%; }

div.home_block > span.text {
  vertical-align: top;
  padding: 0 20px 0 0;
  display: inline-block; }

div.home_title {
  text-align: center; }

div.home_title p {
  text-align: left;
  padding: 0 10px; }

div.home_title h2 {
  font-size: 200%;
  line-height: 50px; }

div.home_content {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 0 0 30px 0; }

div.home_section {
  height: 300px;
  margin: 0 0 20px 0;
}

div.home_section .home_left_small,
div.home_section .home_right_small,
div.home_section .home_left_large,
div.home_section .home_right_large {
  padding: 5px; }

div.home_section .home_left_small {
  display: inline-block;
  float: left;
  width: 49%;
  text-align: center;
  line-height: 300px; }

div.home_section .home_left_large {
  display: inline-block;
  float: left;
  width: 49%;
  text-align: center;
  line-height: 300px; }

div.home_section .home_right_small {
  display: inline-block;
  float: right;
  width: 49%;
  text-align: center;
  line-height: 300px; }

div.home_section .home_right_large {
  display: inline-block;
  float: right;
  width: 49%;
  text-align: center;
  line-height: 300px; }

div#search_results_summary {
  margin: 20px 0;
}

div#asset_key {
  margin: 10px 0; }

div#asset_key span {
  margin: 0 15px 0 10px;
  font-size: 200%;
}

div#home_buttons {
  text-align: center;
  margin: 30px 0; }

div.home_section h2 {
  font-size: 200%; }

#content {
  min-height: 600px;
  margin-bottom: 10px; }

div#content > h1 {
  margin: 10px 0 20px 0; }

#content h1 {
  margin: 0 0 20px 0; }

#content h2 {
  margin: 20px 0; }

.info_title h2 {
  margin: 0 !important;
  height: 30px;
  line-height: 30px;
  text-align: center; }

.info_content {
  padding: 10px 0;
  height: 250px;
  margin: 0 0 20px 0; }

p#get_started_button {
  text-align: center;
  margin: 0 0 40px 0 !important; }

.filter_assets {
  margin: 20px 0 20px 0 !important; }

p.reset {
  text-align: center; }

p.center {
  text-align: center; }

#content p {
  line-height: 1.25;
  margin: 0 0 10px 0; }

#main404 {
  margin: 40px 0 0 0;
}

p.error404,
p.large {
  font-family: 'Gafata', sans-serif;
  font-size: 200%;
  margin: 20px; }

#content label {
  width: 160px;
  display: inline-block; }

div#form_complete_controls {
  margin: 20px auto;
  text-align: center; }

  div.spacer {
    height: 70px;
    clear: both;
  }

.form_spacer {
  padding-left: $form_spacer;
  padding-top: 3px;
  display: block;
}

.small {
  font: 12px/1.25 $font-family;
}

figure figcaption {
@extend .small;
  width: 300px;
  margin: 10px auto;
}

.error {
  color: #f00;
  font-weight: bold;
}

.form_option {
  padding-top: 5px; }

.field_description {
  margin: 0 0 0 10px;
  display: none; }

.visible {
  display: inline-block !important;
}

.content_section {
  @include dark_border;
  padding: 10px;
  margin: 10px 0 0 0;
  background: $box_background;
  -webkit-box-shadow: 0 2px 2px 0 #aaa;
  box-shadow: 0 2px 2px 0 #aaa;
}

div.pie {
  display: inline-block;
  margin: 0 10px; }

.chart_common, canvas#chart_currencies {
  width: 200px;
  height: 200px;
  margin: 20px auto;
  text-align: center;
  display: inline-block; }

canvas#chart_currencies {
  cursor: help; }

#currency_explanation {
  display: inline-block;
  margin: 10px auto; }

table#asset_list tr td {
  vertical-align: middle;
}

tr.modal_header td {
  font-weight: bold;
}

div#warranty_info,
div#attachment_info,
table.dashboard {
  text-align: left;
  margin: 0 0 10px 0; }

.row_header {
  height: 30px;
  line-height: 30px;
  background: transparent !important; }

.row_header td {
  text-align: center; }

table.dashboard td {
  line-height: 25px;
  height: 25px;
  padding: 0 10px 0 10px; }

.row_summary,
.row_details {
  padding: 0 10px 0 0; }

form.deleteAssetForm {
  display: inline;
}

div#login_top,
div#form_top {
  border-top-left-radius: $big_radius;
  border-top-right-radius: $big_radius;
  height: 40px;
  line-height: 40px;
  border: 1px solid black;
  margin: 0 auto;
  width: 500px;
  text-align: left;
  color: #fff;
  @include form_theme($main_theme);
}

div#login_top h1,
div#form_top h1 {
  margin-left: 20px; }

div#login_top h1 i,
div#form_top h1 i {
  margin-right: 10px; }

div#b-tabs {
  border: 1px solid #aaa;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 5px;
  margin-bottom: 30px;
}

div#login_form,
div#small_form {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px 0;
  border: 1px solid black;
  width: 500px;
  margin: 0 auto;
  text-align: left;
  padding: 10px 0; }

div#login_form input[type="text"],
div#login_form input[type="password"],
div#small_form input[type="text"],
div#small_form input[type="password"] {
  width: 450px;
  border: none;
  padding: 5px; }

input.not-allowed {
  cursor: not-allowed !important;
}

input[type="text"],
input[type="password"],
textarea,
select {
  // background: #f0f0f0;
  padding: 5px;
  font-size: 100%;
  width: 250px !important;
  // border: 1px solid;
}

textarea.contact_message {
  border: medium none;
  width: 450px; }

.input_warning {
  background-image: url(/img/form_warning.png) !important;
  background-repeat: no-repeat !important;
  border: 1px solid #fc8620 !important;
  background-color: #ffeac8 !important;
  background-position: right !important; }

#form_warnings {
  display: none;
  margin: 0 0 10px 0; }

select {
  width: 264px; }

div#status_new {
  margin-top: 30px;
  text-align: center;
  display: none;
}

span#conversion_errors {
  @extend .small;
  color: #fa0000;
  display: block;
}

ol#help {
  margin: 0 0 20px 0;
  list-style: decimal; }

ol#help li {
  margin: 0 0 0 40px; }

.help-q {
  font-style: italic;
  margin: 0 0 10px 0; }

.help-q:before {
  content: "Q."; }

.help-a {
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px #eee solid; }

.help-a:before {
  content: "A."; }

ul.pagination {
  list-style: none;
  background: none;
  display: inline;
}

ul.pagination li {
  list-style-type: none;
  display: inline-block;
  margin: 0 5px 0 5px; }

ul.pagination li a {
  text-decoration: none; }

#top-stripe {
  height: 130px;
  color: #fff;
  padding: 10px;
  // margin: 0 0 20px 0;
  margin: 0;
  border-bottom: 1px solid #000;
  @include stripe_theme($main_theme);
}

#top-stripe h1 {
  font-size: 400%; }

#top-stripe h1 a {
  color: #fff;
  text-decoration: none; }

/* boxes */
.box_error {
@include box_common;
  background: url(/img/box_error.png) no-repeat $box_bg_left $box_bg_top #ffeac8;
  border-color: #ff810f;
  color: #bf5f0b;
}

.box_warning {
@include box_common;
  background: url(/img/box_warning.png) no-repeat $box_bg_left $box_bg_top #fffadd;
  border-color: #f0e68c;
  color: #747474;
}

.box_ok {
@include box_common;
  border-color: #a2d392;
  color: #599847;
  background: url(/img/box_ok.png) no-repeat $box_bg_left $box_bg_top #e4f5df;
}

.box_info {
@include box_common;
  background: url(/img/box_info.png) no-repeat $box_bg_left $box_bg_top #deeef7;
  border-color: #82c3e6;
  color: #2f81b0;
}

.ok {
  color: #599847; }

.error {
  color: #bf5f0b; }

.spinning {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
